import "./navigation.scss";
import React, {Component} from "react";
import {BlockingNavLink} from "global/utils/blocking-link";
import footerLogo from 'global/images/comp2023/footer_logo.png'
import footerFBLogo from 'global/images/ftc2024/facebook.png'
import footerIGLogo from 'global/images/ftc2024/IG.png'
import footerTWLogo from 'global/images/ftc2024/TW.png'
import footerYTLogo from 'global/images/ftc2024/YT.png'
import NavigationNode from "./navigation-node";
import {connect} from "react-redux";
import fieldsArrayToKeyValueHash from "global/utils/fieldsArrayToKeyValueHash";
import $ from 'jquery'

import logoWhite from 'global/images/ftc2024/logo-white.png'

class NavCustom extends Component {

    state = {
        "isLoaded": false,
        "openMenu": false,
    };

    constructor (props) {
        super(props);
    }

    componentDidMount () {
        window.addEventListener("resize", this.handleWindowResize);
        window.addEventListener("click", this.checkClick);
    }

    checkClick = (e) => {
      const clickTarget = e.target

      if($(clickTarget).hasClass('navigation__nav-link')) {
        const target = $(clickTarget).attr('href')
        if(target.indexOf('#') !== -1) {
          const t = $(target)
          const scrollOffset = t.offset().top
          $('html, body').animate({
            scrollTop: scrollOffset - 100
          }, 800)
        }
        this.closeMenu()
      }
    }

    toggleInert = (elements, isInert) => {

        elements.forEach((element) => {

            if (document.querySelector(element)) {

                if (isInert) {

                    document.querySelector(element).setAttribute("inert", isInert);

                } else {

                    // Have to manage this way as just setting to false has no effect and element remains inert
                    document.querySelector(element).removeAttribute("inert");

                }

            }

        });

    }

    manageInertElements = (isInert) => {

        this.toggleInert([
            ".page__content",
            ".page__footer",
            ".navigation__brand",
        ], isInert);

    }

    handleWindowResize = () => {

        this.viewportWith = window.innerWidth;
        this.largeBreakpoint = 1200;

        if (this.viewportWith >= this.largeBreakpoint) {

            this.closeMenu();

        }

    }

    toggleMenu = () => {

        this.setState({openMenu: !this.state.openMenu});
        this.manageInertElements(!this.state.openMenu);

    }

    closeMenu = () => {

        this.setState({openMenu: false});
        this.manageInertElements(false);

    }

    render () {

        const logoData = this.props.navdata.logo
        const navLinkData = this.props.navdata.navigation
        const isOpenClass = this.state.openMenu && "navigation--toggled";
        
        return (
            <nav className={`navigation ${isOpenClass || ""}`}
                role="navigation"
                id="mainNav"
            >
              {navLinkData && navLinkData.length > 0 &&
                <div className="navigation__menu"
                    aria-modal={`${this.state.openMenu && "true"}`}>
                    <div className="navigation__toggles">
                        <button
                            id="main-nav__toggle"
                            className={`navigation__toggle navigation__toggle--options
                            hamburger hamburger--spin
                            ${this.state.openMenu && "is-active"}`}
                            aria-expanded={`${this.state.openMenu && "true"}`}
                            onClick={() => this.toggleMenu()}
                            type="button"
                            aria-haspopup="true"
                            aria-controls="main-nav__options">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                            <span className="navigation__toggle-text">Toggle navigation</span>
                        </button>
                    </div>
                    <div
                        id="main-nav__options"
                        className="navigation__options"
                        aria-labelledby="main-nav__toggle">
                        <ul
                            className="navigation__nav"
                            role="menu">
                            {
                                navLinkData.map((navigationLink, index) => <NavigationNode
                                    fields={navigationLink}
                                    index={index}
                                    key={`navCustom-${index}`}
                                    onClick={this.closeMenu.bind(this)}
                                />)
                            }
                        </ul>
                    </div>
                </div>
              }
              <div className="navigation__brand">
                <a aria-current="page" className="navigation__brand-link active" title="Captain Morgan Home Page"  href="/en-us/">
                  <img src={logoWhite} alt="Captain Morgan Logo" className="navigation__brand-logo" />
                </a>
              </div>
              <aside className="follow-us">
                <div className="follow-us__inner">
                  <ul className="follow-us__options">
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.facebook.com/CaptainMorganUSA/?brand_redir=138940200780" target="_blank" rel="noopener noreferrer">
                             <div className="styled-icon">
                            <img
                              aria-hidden
                              height="75px"
                              loading="lazy"
                              src={footerFBLogo}
                              width="75px"
                            />
                            </div>
                          <span className="follow-us__option-link-alt">Facebook</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.instagram.com/captainmorganusa/" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">                         
                            <img
                              aria-hidden
                              height="75px"
                              loading="lazy"
                              src={footerIGLogo}
                              width="75px"
                            />
                          </div>
                          <span className="follow-us__option-link-alt">Instagram</span>
                        </a>
                    </li>
                    <li className="follow-us__option">
                        <a className="follow-us__option-link" href="https://www.youtube.com/user/CaptainMorgan" target="_blank" rel="noopener noreferrer">
                          <div className="styled-icon">
                            <img
                              aria-hidden
                              height="75px"
                              loading="lazy"
                              src={footerYTLogo}
                              width="75px"
                            />
                          </div>
                          <span className="follow-us__option-link-alt">YouTube</span>
                        </a>
                    </li>
                  </ul>
                </div>
              </aside>
            </nav>
        );

    }

}

const mapStateToProps = (state) => ({isShopalystActive: state.content.isShopalystActive});

export default connect(mapStateToProps)(NavCustom);
