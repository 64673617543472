import mapPin from 'global/images/maps/map_pin.svg'
import pinShip from 'global/images/ftc2024/pin_ship.png'
import pinEpic from 'global/images/ftc2024/pin_epic.png'
import btnBack from 'global/images/ftc2024/btn__backtomap.png'
import pinCaptainShip from 'global/images/ftc2024/pin_captain_ship.png'
import btnPlay from 'global/images/ftc2024/btn__play.png'
import btnPrizes from 'global/images/ftc2024/btn__prizes.png'
import btnRsvp from 'global/images/ftc2024/btn__rsvpnow.png'

import imgDefault from 'global/images/ftc2024/image-default.jpg'
import imgLocation1 from 'global/images/ftc2024/image-location-1.png'
import imgLocation2 from 'global/images/ftc2024/image-location-2.png'

import shipStopSample from 'global/images/ftc2024/ship_stop_sample.png'
import $ from 'jquery'
import * as moment from 'moment'

const token =
  'pk.eyJ1IjoibXdrcmllZ2VyIiwiYSI6ImNqcDA1NGQ4bDA0cjIzd3BpbGl5NzVvcXMifQ.2Cs5UE0BiZsh_wt36xHRoA'

let map

let markers = []

let filteredLocations = []

let locationsOutput = ''

import highlight1 from 'global/images/ftc2024/events/ftc_2024_highlights_1_1.jpg'
import highlight2 from 'global/images/ftc2024/events/ftc_2024_highlights_1_2.jpg'
import highlight3 from 'global/images/ftc2024/events/ftc_2024_highlights_1_3.jpg'
import highlight4 from 'global/images/ftc2024/events/ftc_2024_highlights_1_4.jpg'
import highlight5 from 'global/images/ftc2024/events/ftc_2024_highlights_1_5.jpg'
import highlight6 from 'global/images/ftc2024/events/ftc_2024_highlights_1_6.jpg'
import highlight7 from 'global/images/ftc2024/events/ftc_2024_highlights_1_7.jpg'
import highlight8 from 'global/images/ftc2024/events/ftc_2024_highlights_1_8.jpg'
import highlight9 from 'global/images/ftc2024/events/ftc_2024_highlights_1_9.jpg'
import highlight10 from 'global/images/ftc2024/events/ftc_2024_highlights_1_10.jpg'
import highlight11 from 'global/images/ftc2024/events/ftc_2024_highlights_1_11.jpg'
import highlight12 from 'global/images/ftc2024/events/ftc_2024_highlights_1_12.jpg'
import highlight13 from 'global/images/ftc2024/events/ftc_2024_highlights_1_13.jpg'
import highlight14 from 'global/images/ftc2024/events/ftc_2024_highlights_1_14.jpg'
import highlight15 from 'global/images/ftc2024/events/ftc_2024_highlights_1_15.jpg'
import highlight16 from 'global/images/ftc2024/events/ftc_2024_highlights_1_16.jpg'
import highlight17 from 'global/images/ftc2024/events/ftc_2024_highlights_1_17.jpg'
import highlight18 from 'global/images/ftc2024/events/ftc_2024_highlights_1_18.jpg'
import highlight19 from 'global/images/ftc2024/events/ftc_2024_highlights_1_19.jpg'
import highlight20 from 'global/images/ftc2024/events/ftc_2024_highlights_1_20.jpg'

const galleryData = [          
  {
    image: highlight1,
    imagealt: "Follow the Captain 2023 Image 1"
  },
  {
    image: highlight2,
    imagealt: "Follow the Captain 2023 Image 2"
  },
  {
    image: highlight3,
    imagealt: "Follow the Captain 2023 Image 3"
  },
  {
    image: highlight4,
    imagealt: "Follow the Captain 2023 Image 4"
  },
  {
    image: highlight5,
    imagealt: "Follow the Captain 2023 Image 5"
  },
  {
    image: highlight6,
    imagealt: "Follow the Captain 2023 Image 6"
  },
  {
    image: highlight8,
    imagealt: "Follow the Captain 2023 Image 8"
  },
  {
    image: highlight9,
    imagealt: "Follow the Captain 2023 Image 9"
  },
  {
    image: highlight10,
    imagealt: "Follow the Captain 2023 Image 10"
  },
]

// Delete markers
function clearMarkers() {
  markers.forEach((marker) => {
    marker.remove()
  })
  markers = []
}

function getLocationImage(imageName) {
  let sendImage = ''

  switch (imageName) {
    case 'ship_stop_sample':
      sendImage = shipStopSample
      break
    case 'image-location-1':
      sendImage = imgLocation1
      break
    case 'image-location-2':
      sendImage = imgLocation2
      break
    default:
      sendImage = ''
      break
  }

  return sendImage
}

function lockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.add('locked')
  bodyElem.classList.add('locked')
  navElem.classList.add('locked')
}

function unLockElements() {
  const htmlElem = document.getElementsByTagName('html')[0]
  const bodyElem = document.getElementsByTagName('body')[0]
  const navElem = document.getElementById('mainNav')

  htmlElem.classList.remove('locked')
  bodyElem.classList.remove('locked')
  navElem.classList.remove('locked')
}

function createLocationsOutput() {
  locationsOutput += '<div class="locevent__wrap">'

  filteredLocations.forEach((loc) => {
    // Console.log(loc)
    if (loc.loctype === 'ship') {
      locationsOutput += '<div class="locevent__item">'
      if (loc.datestart === loc.dateend) {
        locationsOutput += `<div class="locevent__date">${moment(loc.datestart).format(
          'MMMM D'
        )}</div>`
      } else {
        locationsOutput += `<div class="locevent__date">${moment(loc.datestart).format(
          'MMMM D'
        )} - ${moment(loc.dateend).format('MMMM D')}</div>`
      }

      locationsOutput += `<div class="locevent__place">${loc.locaddress}, ${loc.locstate}</div>`
      //LocationsOutput += `<div class="locevent__name">${loc.locname}</div>`

      locationsOutput += '</div>'
    }
  })

  locationsOutput += '</div>'
}

export const setLocations = (loclist) => {
  if (typeof loclist === 'object' && loclist.length > 0) {
    // Set the filtered list
    filteredLocations = loclist
    createLocationsOutput()
  }
}

export const init = () => {
  const windowWidth = document.documentElement.clientWidth
  const center = windowWidth < 768 ? [-70.5797656, 37.3125119] : [-96, 37.8]

  mapboxgl.accessToken = token
  map = new mapboxgl.Map({
    container: 'map', // Container ID
    style: 'mapbox://styles/wvisokay/clz2wx9pd00m801pob1cpdixv',
    center,
    zoom: 1,
    maxZoom: 7,
    minZoom: 1,
    pitch: 10,
    maxBounds: [
      [-126.791110603, 24.91619],
      [-65.96466, 49.3577635769]
    ],
    attributionControl: false,
    // FitBoundsOptions: { padding: 100}
    cooperativeGestures: true
  })
  if (map) {
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()
    map.fitBounds(
      [
        [-126.791110603, 24.91619],
        [-65.96466, 49.3577635769]
      ],
      { padding: { top: 0, bottom: 150, left: 150, right: 150 } }
    )
  }

  if (windowWidth < 768) {
    flyToMarker(32.3125119, -73.5797656, 4)
  }
  console.log('INIT MAP')
}

export const flyToMarker = (lat, lon, zoom) => {
  if (lat && lon) {
    const zoomLevel = zoom ? zoom : 4

    map.flyTo({
      zoom: zoomLevel,
      speed: 2,
      pitch: 10,
      center: [Number(lon), Number(lat)]
    })
  }
}

function createShipMarker(lat, lon, popupcontent, showrsvp, callback, that) {
  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [75, 75]
      },
      geometry: {
        type: 'Point',
        coordinates: [-70.5797656, 37.3125119]
      }
    }
  }
  // Add markers to map

  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${pinCaptainShip}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  if (showrsvp) {
    popupcontent += '<div class="locCta">'
    popupcontent +=
      '<button class="btn__locCta" id="buttonCta" type="button" aria-label="Play the game">'
    popupcontent += `<img src="${btnPrizes}" aria-hidden="true" />`
    popupcontent += '</button>'
    popupcontent += '</div>'
  }

  const popup = new mapboxgl.Popup().setHTML(popupcontent)

  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName('event-captain')
    .addClassName('event__pin')
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  markers.push(marker)

  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById('buttonCta')

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]

      popup.addClassName('slideInOut')

      if (popupDiv) {
        popupDiv.scrollTop = 0
      }
      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')
        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        initGallery()

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          4
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })

  // const routeLayer = {
  //   id: 'route',
  //   type: 'line',
  //   paint: {
  //     'line-width': 3,
  //     'line-color': '#fff',
  //     'line-dasharray': [2, 2]
  //   },
  //   source: {
  //     type: 'geojson',
  //     data: {
  //       type: 'Feature',
  //       properties: {},
  //       geometry: {
  //         type: 'LineString',
  //         coordinates: [
  //           [-70.5797656, 37.3125119],
  //           [lon, lat]
  //         ]
  //       }
  //     }
  //   }
  // }

  // map.addLayer(routeLayer)
}

export const createMarkers = (loc, numbers, callback, that) => {
  const iconType = loc.loctype || 'ship'

  let useMapPin = pinShip

  if (loc.loctype === 'epic') {
    useMapPin = pinEpic
  }

  const geojson = {
    type: 'FeatureCollection',
    features: {
      type: 'Feature',
      properties: {
        iconSize: [32, 32]
      },
      geometry: {
        type: 'Point',
        coordinates: [Number(loc.loclon), Number(loc.loclat)]
      }
    }
  }

  // Variables for marker popups

  const locName = loc.locname ? loc.locname : ''
  const locAddress = loc.locaddress ? loc.locaddress : ''
  const useImage = loc.locimage && loc.locimage !== '' ? getLocationImage(loc.locimage) : ''
  const locDate = loc.datestart ? loc.datestart : ''
  const useDate = locDate !== '' ? moment(locDate).format('MMMM D, YYYY') : ''
  const useLink = loc.loclink !== '' ? loc.loclink : '/followthecaptain/rsvp'

  const useCopy = loc.loccopy ? loc.loccopy : ''

  const locState = loc.locstate ? loc.locstate : ''

  let dateLine = ''

  let locTitle = loc.locname ? loc.locname : locName

  if (iconType === 'epic') {
    dateLine = `${locAddress}, ${locState} | ${useDate}`
  } else {
    locTitle = `${locAddress}, ${locState}`
    dateLine = useDate
  }

  // Creates inner html for marker popup
  let infoWindowTag = ''

  infoWindowTag += `<div class="backtomap"><img src=${btnBack} alt="Back to map" aria-label="Back to map" /><span class="sr-only">< BACK TO MAP</span></div>`
  infoWindowTag += `<div id="location_${numbers}" class="locationItem" style="cursor: pointer;">`
  infoWindowTag += `<div class="locationAddress">${locTitle}</div>`
  infoWindowTag += `<div class="locationDate">${dateLine}${
    useLink === 'NONE' ? ' - Coming Soon' : ''
  }</div>`

  infoWindowTag += '<div class="locDescription">'
  if (useCopy === '') {
    infoWindowTag += `Legendary adventure awaits when we drop anchor for the ${locTitle} game. <br />`
    infoWindowTag += 'Come out and join the crew.'
  } else {
    infoWindowTag += useCopy
  }

  infoWindowTag += '</div>'

  if (iconType === 'epic') {
    infoWindowTag += `<div class="gallery">`
    infoWindowTag += `<div class="swiper-container gallery-slider">`
    infoWindowTag += `<div class="swiper-wrapper">`
    infoWindowTag += renderGalleryItems('gallery')
    infoWindowTag += `</div>`
    infoWindowTag += `</div>`
    infoWindowTag += `<div class="swiper-container gallery-thumbs">`
    infoWindowTag += `<div class="swiper-wrapper">`
    infoWindowTag += renderGalleryItems('thumbnail')
    infoWindowTag += `</div>`
    infoWindowTag += `<div class="swiper-button-prev"></div>`
    infoWindowTag += `<div class="swiper-button-next"></div>`
    infoWindowTag += `</div>`
    createShipMarker(
      geojson.features.geometry.coordinates[1],
      geojson.features.geometry.coordinates[0],
      infoWindowTag,
      loc.showrsvp,
      callback,
      that
    )
  }

  if (iconType === 'epic') {
    if (loc.showrsvp) {
      infoWindowTag += '<div class="locCta">'
      infoWindowTag += `<button class="btn__locCta" id="buttonCta_${loc.id}" type="button" aria-label="Play the game">`
      infoWindowTag += `<img src="${btnPrizes}" aria-hidden="true" />`
      infoWindowTag += '</button>'
      infoWindowTag += '</div>'
    }
  } else {
    if (useLink !== 'NONE') {
      infoWindowTag += '<div class="locLink">'
      // InfoWindowTag += `<a class="btn btn--reset" href="${useLink}" target="_blank" rel="noreferrer" title="RSVP NOW">`
      infoWindowTag += `<a class="btn btn--reset btn--ga" href="${useLink}" target="_blank" rel="noreferrer" title="RSVP NOW" data-text="Rsvp now" data-url="${useLink}" data-name=${locTitle}>`
      infoWindowTag += `<img src=${btnRsvp} aria-label="RSVP now" />`
      infoWindowTag += '<span class="sr-only">RSVP NOW</span></a>'
      infoWindowTag += '</div>'
    }

    if (useImage !== '') {
      infoWindowTag += '<div class="locSubCopy padbot">Come out and join the crew</div>'
      infoWindowTag += `<div class="image__wrap"><img src=${useImage} alt="Come out and join the crew" /></div>`
    }
    infoWindowTag += '<div class="locSubCopy">Everywhere we\'re headed:</div>'
    infoWindowTag += locationsOutput
  }
  infoWindowTag += '</div>'

  // Add markers to map
  const markerIcon = document.createElement('div')

  markerIcon.style.backgroundImage = `url("${useMapPin}")`
  markerIcon.style.width = `${geojson.features.properties.iconSize[0]}px`
  markerIcon.style.height = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.lineHeight = `${geojson.features.properties.iconSize[1]}px`
  markerIcon.style.cursor = 'pointer'
  markerIcon.style.verticalAlign = 'middle'
  markerIcon.style.textAlign = 'center'

  const popup = new mapboxgl.Popup().setHTML(infoWindowTag)
  // Push each marker into a global array
  // So that they can can be deleted later on
  let eventPassed = moment(locDate).isBefore(new Date())

  // if (iconType === 'epic') {
  //   eventPassed = false
  // }
  const marker = new mapboxgl.Marker(markerIcon)
    .addClassName(`event-${iconType}`)
    .addClassName('event__pin')
    .addClassName(`${(eventPassed && iconType !== 'epic') ? 'event__pin--disabled' : 'event__pin--active'}`)
    .addClassName(`${eventPassed ? 'event-passed' : 'event-running'}`)
    .setLngLat(geojson.features.geometry.coordinates)
    .setPopup(popup)
    .addTo(map)

  // This prevents the popup from showing on a disabled pin
  marker.getElement().addEventListener(
    'click',
    (e) => {
      const currentPin = e.target

      if (currentPin.classList.contains('event__pin--disabled')) {
        e.stopPropagation()
        // E.preventDefault()
      }
    },
    false
  )
  markers.push(marker)

  // Sets the slide in animation for the popup
  popup.on('open', () => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')
    const buttonCta = document.getElementById(`buttonCta_${loc.id}`)

    lockElements()

    $(
      '.mapboxgl-popup-close-button,.mapboxgl-marker.mapboxgl-marker-anchor-center.event__pin, .mapboxgl-canvas'
    ).on('click', () => {
      $('.mapboxgl-canvas-container .event__pin').each(function () {
        if ($(this).attr('aria-expanded') === true || $(this).attr('aria-expanded') === 'true') {
          $(this).attr('aria-expanded', 'false')
        }
      })
    })

    if (buttonCta) {
      buttonCta.addEventListener('click', () => {
        callback(true, that, 'card')
        $('.mapboxgl-popup-close-button').trigger('click')
      })
    }
    setTimeout(() => {
      const popupDiv = document.getElementsByClassName('mapboxgl-popup')[0]
      popup.addClassName('slideInOut')
      if (popupDiv) {
        popupDiv.scrollTop = 0
      }

      setTimeout(() => {
        mapDiv.classList.add('mapdiv__map--pushright')

        nflPModal.classList.add('z-top')
        filters.classList.add('z-top')

        if (loc.loctype === 'epic') {
          initGallery()
          $('.event-captain').addClass('flag-triggered')
        }

        flyToMarker(
          geojson.features.geometry.coordinates[1],
          geojson.features.geometry.coordinates[0],
          loc.loctype === 'epic' ? 4 : 6
        )
      }, 300)
    }, 300)
  })

  popup.on('close', (e) => {
    const mapDiv = document.getElementById('map')
    const nflPModal = document.getElementById('nflPModal')
    const filters = document.getElementById('filter')

    unLockElements()

    if (loc.loctype === 'epic') {
      $('.event-captain').removeClass('flag-triggered')
    }

    mapDiv.classList.remove('mapdiv__map--pushright')
    nflPModal.classList.remove('z-top')
    filters.classList.remove('z-top')
    flyToMarker(37.8, -96)
  })
}

function initGallery() {
  const gallerySlider = document.getElementsByClassName('gallery-slider')

  if (gallerySlider.length > 0) {
    var thumbs = new Swiper ('.gallery-thumbs', {
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      loop: true,
      spaceBetween: 10
    });
    var slider = new Swiper ('.gallery-slider', {
      slidesPerView: 1,
      // centeredSlides: true,
      loop: true,
      loopedSlides: 6,
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: thumbs
      }
    });
  }
}

function renderGalleryItems(type) {
  let items = ''

  if (type === 'gallery') {
    galleryData && galleryData.length > 0 && galleryData.map((item, key) => {
      items += `<div key=${`gallery_item_${key}`}  class="swiper-slide" data-index=${key} data-status=${key === 0 ? 'active' : "unknown"}>
          <div class="content-wrap">
            <img src=${item.image} alt=${item.imagealt} />
          </div>
        </div>`
    })
  } else if (type === 'thumbnail') {
    galleryData && galleryData.length > 0 && galleryData.forEach((item, key) => {
      items += `<div key=${`gallery_thumb_item_${key}`}  class="swiper-slide" data-index=${key} data-status=${key === 0 ? 'active' : "unknown"}>
          <div class="content-wrap">
            <img src=${item.image} alt=${item.imagealt} />
          </div>
        </div>`
    })
  }

  return items
}

// export const mapData = [
//     {
//         "id": 1,
//         "locname": "Miami v Florida Game",
//         "locaddress": "Gainesville",
//         "locstate": "FL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "29.6626973",
//         "loclon": "-82.3761179",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-ship-event",
//         "datestart": "2024-08-31",
//         "dateend": "2024-08-31",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 2,
//         "locname": "California v Auburn Game",
//         "locaddress": "Auburn",
//         "locstate": "AL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "32.5980594",
//         "loclon": "-85.4968416",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-auburn-auburn",
//         "datestart": "2024-09-07",
//         "dateend": "2024-09-07",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 3,
//         "locname": "Texas AM v Florida Game",
//         "locaddress": "Gainesville",
//         "locstate": "FL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "29.686245",
//         "loclon": "-82.4021466",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-gainesville",
//         "datestart": "2024-09-14",
//         "dateend": "2024-09-14",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 4,
//         "locname": "Alabama v Wisconsin Game",
//         "locaddress": "Madison",
//         "locstate": "WI",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "43.0850131",
//         "loclon": "-89.562474",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-wisconsin-madison",
//         "datestart": "2024-09-14",
//         "dateend": "2024-09-14",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 5,
//         "locname": "USC v Michigan Game",
//         "locaddress": "Ann Arbor",
//         "locstate": "MI",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "42.2733765",
//         "loclon": "-83.7788887",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-ann-arbor-ann-arbor",
//         "datestart": "2024-09-21",
//         "dateend": "2024-09-21",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 6,
//         "locname": "Captain's Cruise with Cruz",
//         "locaddress": "New York",
//         "locstate": "NY",
//         "loczipcode": "",
//         "loctype": "epic",
//         "locflagcolor": "",
//         "loclat": "40.7368248",
//         "loclon": "-73.9276993",
//         "locimage": "",
//         "loccopy": "Captain Morgan has set sail.<br /><br />Check back soon for our next Adventure!",
//         "loclink": "",
//         "datestart": "2024-09-26",
//         "dateend": "2024-09-26",
//         "active": 1,
//         "showrsvp": 0
//     },
//     {
//         "id": 7,
//         "locname": "Georgia v Alabama Game",
//         "locaddress": "Tuscaloosa",
//         "locstate": "AL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "33.2869979",
//         "loclon": "-87.5345087",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-tuscaloosa-tuscaloosa",
//         "datestart": "2024-09-28",
//         "dateend": "2024-09-28",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 8,
//         "locname": "USC v Minnesota Game",
//         "locaddress": "Minneapolis",
//         "locstate": "MN",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "44.9707888",
//         "loclon": "-93.343878",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-minneapolis-minneapolis",
//         "datestart": "2024-10-05",
//         "dateend": "2024-10-05",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 9,
//         "locname": "UCLA v Penn State Game",
//         "locaddress": "State College",
//         "locstate": "PA",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "40.7880302",
//         "loclon": "-77.8628712",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-penn-state",
//         "datestart": "2024-10-05",
//         "dateend": "2024-10-05",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 10,
//         "locname": "Purdue v Illinois Game",
//         "locaddress": "Champaign",
//         "locstate": "IL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "40.1146894",
//         "loclon": "-88.3183088",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-champaign-champaign",
//         "datestart": "2024-10-12",
//         "dateend": "2024-10-12",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 11,
//         "locname": "Iowa v Michigan St Game",
//         "locaddress": "East Lansing",
//         "locstate": "MI",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "42.7087372",
//         "loclon": "-84.6006268",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-east-lansing-east-lansing",
//         "datestart": "2024-10-19",
//         "dateend": "2024-10-19",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 12,
//         "locname": "Michigan St v Michigan Game",
//         "locaddress": "Ann Arbor",
//         "locstate": "MI",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "42.2733765",
//         "loclon": "-83.7788887",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-ann-arbor-ann-arbor-7d56eada-65d6-4843-bb5f-8809dc0f2f6c",
//         "datestart": "2024-10-26",
//         "dateend": "2024-10-26",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 13,
//         "locname": "Penn St v Wisconsin Game",
//         "locaddress": "Madison",
//         "locstate": "WI",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "43.0850131",
//         "loclon": "-89.562474",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-wisconsin-madison-aea302ac-e8e2-4fe7-9806-4c8a9b49a386",
//         "datestart": "2024-10-26",
//         "dateend": "2024-10-26",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 14,
//         "locname": "Florida v Georgia Game",
//         "locaddress": "Jacksonville",
//         "locstate": "FL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "30.345183",
//         "loclon": "-81.8479115",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=copy-of-captain-morgan-tailgate-tour-jacksonville-jacksonville",
//         "datestart": "2024-11-02",
//         "dateend": "2024-11-02",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 15,
//         "locname": "Purdue v Ohio St Game",
//         "locaddress": "Columbus",
//         "locstate": "OH",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "39.9830978",
//         "loclon": "-83.1556326",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-columbus-columbus",
//         "datestart": "2024-11-09",
//         "dateend": "2024-11-09",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 16,
//         "locname": "Crewsgiving",
//         "locaddress": "Miami",
//         "locstate": "FL",
//         "loczipcode": "",
//         "loctype": "epic",
//         "locflagcolor": "",
//         "loclat": "25.782479",
//         "loclon": "-80.2706578",
//         "locimage": "",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "",
//         "datestart": "2024-11-15",
//         "dateend": "2024-11-15",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 17,
//         "locname": "LSU v FL Game",
//         "locaddress": "Gainesville",
//         "locstate": "FL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "29.6818691",
//         "loclon": "-82.2760401",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=copy-of-captain-morgan-tailgate-tour-gainesville-gainesville",
//         "datestart": "2024-11-16",
//         "dateend": "2024-11-16",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 18,
//         "locname": "Virgina v Notre Dame Game",
//         "locaddress": "Holy Cross",
//         "locstate": "IN",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "41.7008923",
//         "loclon": "-86.2574938",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-notre-dame-notre-dame",
//         "datestart": "2024-11-16",
//         "dateend": "2024-11-16",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 19,
//         "locname": "Texas AM v Auburn Game",
//         "locaddress": "Auburn",
//         "locstate": "AL",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "32.6280206",
//         "loclon": "-85.5709313",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-auburn-auburn-02c86629-b7cd-4097-a147-b4f4cc11d059",
//         "datestart": "2024-11-23",
//         "dateend": "2024-11-23",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 20,
//         "locname": "Texas v Texas AM Game",
//         "locaddress": "College Station",
//         "locstate": "TX",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "30.5909183",
//         "loclon": "-96.3328604",
//         "locimage": "image-location-2",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-texas-am-college-station",
//         "datestart": "2024-11-30",
//         "dateend": "2024-11-30",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 21,
//         "locname": "Arizona St v Arizona Game",
//         "locaddress": " Tucson",
//         "locstate": "AZ",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "32.1560286",
//         "loclon": "-110.9662048",
//         "locimage": "image-location-1",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "/followthecaptain/rsvp#id=captain-morgan-tailgate-tour-az-tucson",
//         "datestart": "2024-11-30",
//         "dateend": "2024-11-30",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 22,
//         "locname": "Gasparilla",
//         "locaddress": "Tampa",
//         "locstate": "FL (Gasparilla)",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "27.9946096",
//         "loclon": "-82.5366804",
//         "locimage": "image-location-2",
//         "loccopy": "We're bringing adventure to the legendary pirate's festival, Gasparilla!",
//         "loclink": "",
//         "datestart": "2025-01-25",
//         "dateend": "2025-01-25",
//         "active": 1,
//         "showrsvp": 1
//     },
//     {
//         "id": 23,
//         "locname": "Super Bowl",
//         "locaddress": "New Orleans",
//         "locstate": "LA",
//         "loczipcode": "",
//         "loctype": "epic",
//         "locflagcolor": "",
//         "loclat": "29.950045",
//         "loclon": "-90.0805075",
//         "locimage": "",
//         "loccopy": "A legendary adventure is dropping anchor!",
//         "loclink": "",
//         "datestart": "2025-02-06",
//         "dateend": "2025-02-09",
//         "active": 0,
//         "showrsvp": 1
//     },
//     {
//         "id": 24,
//         "locname": "Mardi Gras",
//         "locaddress": "New Orleans",
//         "locstate": "LA (Mardi Gras)",
//         "loczipcode": "",
//         "loctype": "ship",
//         "locflagcolor": "",
//         "loclat": "30.0331194",
//         "loclon": "-89.9649633",
//         "locimage": "image-location-1",
//         "loccopy": "We're bringing a legendary adventure to Mardi Gras!",
//         "loclink": "",
//         "datestart": "2025-02-27",
//         "dateend": "2025-03-04",
//         "active": 1,
//         "showrsvp": 1
//     }
// ]
