import $ from 'jquery'

  const waitForElm = (selector) => {
        return new Promise((resolve) => {
          if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector))
          }

          const observer = new MutationObserver((mutations) => {
            if (document.querySelector(selector)) {
              resolve(document.querySelector(selector))
              observer.disconnect()
            }
          })

          observer.observe(document.body, {
            childList: true,
            subtree: true,
          })
        })
      }

function isInViewport(divToLock, topoffset) {                    
  const htmlHeight = $('html').outerHeight()
  const currentScroll = parseFloat(window.scrollY)
  const docWidth = $('body').width()
  const regFooterHeight = $(`#${divToLock}`).height()
  const fullHeight = $(`#${divToLock}`).hasClass('fixed') ? htmlHeight + regFooterHeight : htmlHeight
  let scrollPercent = .15

  if (docWidth > 991) {
      scrollPercent = .10
  }

  return (currentScroll >= parseFloat(fullHeight * scrollPercent))
}

function trackElementPosition(divToTrack, divToLock) {
  const elem = document.getElementById(divToTrack)
  if (elem) {
      const inView = isInViewport(divToLock, -55)
      return inView
  } else {
      return false
  }
}

function triggerBarLock(divToTrack, divToLock, lockedHeight, initialHeight) {
  if (!trackElementPosition(divToTrack, divToLock)) {       
      if (!$(`#${divToLock}`).hasClass('fixed')) {
          $(`#${divToLock}`).addClass('fixed')
          console.log(54)
          $(`#${divToLock}`).parent().css('padding-bottom',parseInt(lockedHeight)) 
      }
  } else {

      if ($(`#${divToLock}`).hasClass('fixed')) {
   
          $(`#${divToLock}`).removeClass('fixed')
          console.log(62)
           $(`#${divToLock}`).parent().css('padding-bottom',parseInt(initialHeight)) 
      }
  }

}


export const init = (divToTrack, divToLock) => {
   
  console.log("init")
  waitForElm(`#${divToLock}`).then((elm) => {
  const parent = $(`#${divToLock}`).parent()
    const parentPaddingBottom = parent.css("padding-bottom")
    const parentPaddingBottomNumber =parentPaddingBottom.substring(0, parentPaddingBottom.length-2)
    const elemHeight = $(`#${divToLock}`).outerHeight();
    const lockedHeight = parseInt(parentPaddingBottomNumber) + parseInt(elemHeight)

    $(`#${divToLock}`).parent().css('padding-bottom',lockedHeight) 
    console.log($(`#${divToLock}`).parent())

      triggerBarLock(divToTrack, divToLock,lockedHeight,parentPaddingBottomNumber)
      $(window).on('scroll resize', function (e) {
        e.stopPropagation()
          triggerBarLock(divToTrack, divToLock,lockedHeight,parentPaddingBottomNumber)
      })

    })
   

 
}
