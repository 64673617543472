/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'

import * as utils from 'global/utils/utils'

import AppError from "../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import HeadingWithCopy from "../heading-with-copy/";
import Footer from "../footer";
import CloudLayer from "../cloud-layer/";
import buildPage from "../../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../nav-custom";

const cn = require('classnames')

import heroImage from 'global/images/ftc2024/bg-red.jpg'
import fotyBanner from 'global/images/ftc2024/NFL_FOY_spon_horz_alt3_RGB_r.png'
import fotyBannerMobile from 'global/images/ftc2024/foty_banner--mobile.png'
import btnFoty from 'global/images/ftc2024/btn__foty.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "image": fotyBanner,
      "imageMobile": fotyBannerMobile,
      "imagealt": "NFL Fan of the Year, presented by Captain Morgan",
      "title": "",      
      "srText": "NFL Fan of the Year, presented by Captain Morgan",
      "copy": "The NFL Fan of the Year contest presented by Captain Morgan celebrates extraordinary fans who inspire others and SPICE UP the game through their love of football.",
      "imageSize":"foty",
      "pModalOpen": false,
    },
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler () {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }
  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {
      this.props.fetchData(
          {name: newLocation},
          false
      );
    }
  }

  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text')
        var btnHref = $(this).data('url')
        var btnName = $(this).data('name')
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnHref,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })

      // Set the date we're counting down to
      // const countDownDate = new Date('2024-09-30T09:00:00.000-04:00').getTime()
      // // Update the count down every 1 second
      // let x = setInterval(function () {
      //   // Get today's date and time
      //   const now = new Date().getTime()

      //   // Find the distance between now and the count down date
      //   const distance = countDownDate - now

      //   // Time calculations for days, hours, minutes and seconds
      //   let days = Math.floor(distance / (1000 * 60 * 60 * 24))
      //   let hours = Math.floor(
      //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      //   )
      //   let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      //   let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      //   // Display the result in the element with id="demo"
      //   //   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
      //   //   + minutes + "m " + seconds + "s ";
      //   const daysDiv = document.getElementById('days')
      //   const hoursDiv = document.getElementById('hours')
      //   const minutesDiv = document.getElementById('minutes')
      //   const secDiv = document.getElementById('seconds')

      //   if(daysDiv && hoursDiv && minutesDiv && secDiv) {
      //     daysDiv.innerHTML = days
      //     hoursDiv.innerHTML = hours
      //     minutesDiv.innerHTML = minutes
      //     secDiv.innerHTML = seconds
      //     // If the count down is finished, write some text
      //     if (distance < 0) {
      //       clearInterval(x)
      //       document.getElementById('days').innerHTML =
      //           '<span>' + '0' + '</span> <br/> days '
      //       document.getElementById('hours').innerHTML =
      //           '<span>' + '0' + '</span> <br/> hours '
      //       document.getElementById('minutes').innerHTML =
      //           '<span>' + '0' + '</span> <br/> minutes '
      //       document.getElementById('seconds').innerHTML =
      //             '<span>' + '0' + '</span> <br/> seconds '
      //     }
      //   }
      // }, 1000)
    })
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "NFL Fan of the Year | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          inactive: true,
          url: '/followthecaptain/about',
        },
        {
          title: 'NFL Fan of the Year',
          url: '/followthecaptain/nfl-fan-of-the-year',
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          
          <title>{pageTitle}</title>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--foty" id="main" role="main">
          {/* <CloudLayer background={heroImage} /> */}
          {/* DRIZLY SECTION */}
          <section className="flex heading__section foty__heading">
            <div className="container container--1600">
              <Heading {...this.state.headingProps} />
            </div>
            <div className="container container--1600 z-10 mobile-nopad" id="mapContainer">
              
            </div>
          </section>
          {/*<section className="timer">
            <div className="container container--1600">
              <div className="timer__wrap">
                <div className="timer__content countdown">
                  <div className="contdown__block block">
                     <div className="block__text">
                      Days
                    </div>
                    <div className="block__number" id="days">
                      00
                    </div>
                   
                  </div>

                  <div className="contdown__block block">
                    <div className="block__number block__number--spacer">
                      :
                    </div>
                    <div className="block__text">
                      &nbsp;
                    </div>
                  </div>

                  <div className="contdown__block block">
                     <div className="block__text">
                      hours
                    </div>
                    <div className="block__number" id="hours">
                      21
                    </div>
                   
                  </div>

                  <div className="contdown__block block">
                    <div className="block__number block__number--spacer">
                      :
                    </div>
                    <div className="block__text">
                      &nbsp;
                    </div>
                  </div>

                  <div className="contdown__block block">
                     <div className="block__text">
                      minutes
                    </div>
                    <div className="block__number" id="minutes">
                      42
                    </div>
                   
                  </div>

                  <div className="contdown__block block">
                    <div className="block__number block__number--spacer">
                      :
                    </div>
                    <div className="block__text">
                      &nbsp;
                    </div>
                  </div>

                  <div className="contdown__block block">
                     <div className="block__text">
                      seconds
                    </div>
                    <div className="block__number" id="seconds">
                      51
                    </div>
                   
                  </div>
                </div>
              </div>
              <div className="timer__copy text--copy text--center text--white">
                Nominate them before time runs out.
              </div>
            </div>
          </section>*/}
          <section className="twocol flex foty__twocol">
            <div className="container container--1000">
              <div className="twocol__wrap flex">
                <div className="video__wrap flex">
                  <iframe title="vimeo-player" src="https://player.vimeo.com/video/1001986039" frameBorder="0"  width="100%" height="auto" allowFullScreen></iframe>
                </div>
                <div className="cta__wrap flex">
                  <div className="cta__copy text--copy text--white">
                    The nomination period has closed for the 2024 NFL Fan of the Year.
                  </div>
                  {/*<div className="cta__link">
                    <a className="clear" href="https://www.nfl.com/honors/fan-of-the-year/" target="_blank" rel="noreferrer">
                      <img  alt="Nominate Now" className="cta__image" src={btnFoty} />
                    </a>
                  </div>*/}
                </div>
              </div>
            </div>
          </section>
          <section className="flex content">
            <div className="container container--1200">
              <div className="content__wrap flex">
                <div className="content__copy text--copy text--white">
                  Check back in November to see the 32 team nominees and vote for the Ultimate NFL Fan of the Year!
                </div>
              </div>
            </div>
          </section>
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew to get notified of legendary celebrations throughout the season!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    );
  }
}
