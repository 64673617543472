/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
/* eslint-disable no-console */
import "./page-default.scss";
import React, {Component} from "react";
import {Helmet} from 'react-helmet'

import * as utils from 'global/utils/utils'

import AppError from "../../../organisms/app-error";
import NflProgressiveModal, { RenderButton } from "../../../organisms/NflProgressiveModal";
import Heading from "../heading";
import HeadingWithCopy from "../heading-with-copy/";
import Footer from "../footer";
import Partners from "../partners";
import CloudLayer from "../cloud-layer/";
import buildPage from "../../page-builder";
import {load} from "recaptcha-v3";

import NavCustom from "../nav-custom";

const cn = require('classnames')

import heroImage from 'global/images/ftc2024/bg-red.jpg'
import ftcGlitch from 'global/images/ftc2024/ftc-glitch.png'
import ftcGlitchMobile from 'global/images/ftc2024/ftc-glitch--mobile.png'
import aboutBg from 'global/images/ftc2024/about-bg.png'
import aboutBgMobile from 'global/images/ftc2024/about-bg--mobile.png'

import prizeTicket from 'global/images/ftc2024/prize__icon--ticket.png'
import prizeShirt from 'global/images/ftc2024/prize__icon--shirt.png'
import prizeFb from 'global/images/ftc2024/prize__icon--football.png'
import prizeBox from 'global/images/ftc2024/prize__icon--box.png'

export default class PageContainer extends Component {

  state = {
    "recaptcha":"",
    "locationData": [],
    "locationsFiltered": [],
    "firstClicked": false,
    "headingProps": {
      "eyebrow": "Legends start when you",
      "subtext": "Unlock adventures with your crew",
      "image": ftcGlitch,
      "imageMobile": ftcGlitchMobile,
      "imagealt": "Follow the captain",
      "title": "",      
      "srText": "follow the captain",
      "justify": "justify-start",
      "align": "align-start",
      "pModalOpen": false,
    },
  };

  constructor (props) {
    super(props);
    this.fetchData();
  }

  getPageName () {
    const path = this.props.location.pathname
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'>/g, "&#x27;");

    if (path.endsWith("/")) {
      return path;
    }

    return `${path}/`;

  }

  fetchData () {
    const pageName = this.getPageName();

    if (!this.props.data[pageName]) {

        // No! Lots wrong here:
        // - whatabout fetching data?
        // - Transition should be blocked before
        //   We have the data to avoid rendering noop
        this.props.fetchData(
            {name: pageName},
            false
        );

    }
  }

  pModalClickHandler () {
    this.setState({
      pModalOpen: this.state.pModalOpen === true ? false : true
    })
  }
  pModalCloseHandler (that) {
    this.setState({
      pModalOpen: false
    })
  }
  componentDidUpdate (prevProps) {
    const pageName = this.getPageName();
    const newLocation = this.props.location.pathname;
    const {data} = this.props;

    if (prevProps.location.pathname !== newLocation && !data[pageName]) {

        this.props.fetchData(
            {name: newLocation},
            false
        );

    }
  }


  componentDidMount () {
    $(function () {
      $('body').addClass('ftcBody')
      // need to remove newsletter modal, it's conflicting with the form
      if($('#newsletterModal')) {
        $("#newsletterModal").remove()
      }

      $('body').on('click', '.btn--ga', function (e) {
        var btnText = $(this).data('text')
        var btnHref = $(this).data('url')
        var btnName = $(this).data('name')
        var btnSection = $(this).data('section') || ''
        var data = {
          event: 'in_page_button',
          click_text: btnText,
          destination_page_path: btnHref,
          link_url: btnHref,
          section: btnSection,
          location_name: btnName,
        }
        utils.gaSubmit(data)
      })
    })
  }
  
  render () {
    const {data} = this.props;
    const pageName = this.getPageName();

    if (!data[pageName] || data[pageName].isFetching) {
      // In future, avoid this!
      return null;
    }

    const page = buildPage(data, pageName);
    const pageTitle = "About | Follow The Captain 2024 | Captain Morgan"

    // IF the API is unreachable
    // OR the page response is not as expected
    // BuildPage will return null
    // Without this the App will error server side and bring down the node server
    if (page === null) {
      return (
        <div className="page">
          <AppError
              message="This site is currently unavailable - Come back soon."
          />
        </div>
      );
    }

    const navData = {
      logo: {
        url: "https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop",
        alt: "Captain Morgan Logo"
      },
      navigation: [
        {
          title: 'Home',
          inactive: true,
          url: '/followthecaptain',
        },
        {
          title: 'About',
          url: '/followthecaptain/about',
        },
        {
          title: 'NFL Fan of the Year',
          inactive: true,
          url: '/followthecaptain/nfl-fan-of-the-year',
        },
        {
          title: 'Buy Captain',
          inactive: true,
          url: 'https://productshop.captainmorgan.com/social/65f0bdd0b932a830b410bc0b?&utm_medium=referral&utm_campaign=FTC_FY25_websiteMikMak&utm_placement=body&utm_marketing_tactic=conversion',
          newTab: true,
          track: true,
        },
      ]
    }

    return (

      <div className="page mappage pageftc">
        <NflProgressiveModal 
          modalstate={this.state.pModalOpen}
          callback={() => this.pModalCloseHandler()}
          that={this}
        />
        <Helmet htmlAttributes={{ class:"ftc-html"}}>
          <script>var dg_locale="uen-us"</script>
          <meta charset="UTF-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />

          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content="The captain is missing but he left behind some treasure. Play along for a chance to win prizes and once-in-a-lifetime experiences." />
          <meta property="og:image" content={`https://media.captainmorgan.com/media/1543/red-logo.png?mode=crop`} />
          <meta name="og:url" content={`https://www.captainmorgan.com/followthecaptain`} />
          <meta property="og:type" content="website" />

          
          <title>{pageTitle}</title>
        </Helmet>
        <NavCustom navdata={navData} />
        <main className="page__content ftc24 ftc24--about" id="main" role="main">          
          {/* <CloudLayer background={heroImage} /> */}
          {/* DRIZLY SECTION */}
          <section className="flex about__wrap">
            <div className="content__wrap container flex">
              <Heading {...this.state.headingProps} />
              <div className="contentblock flex">
                <div className="contentblock__copy text--copy text--white">
                  We're setting sail from coast to coast, dropping anchor for legendary adventures along the way.<br /><br />
                  Explore the map where you can snag awesome prizes for your crew, stay updated on celebrations across the nation, and find out how to join us for Epic Experiences from our Captain's Cruise with Cruz in NYC to Super Bowl LIX in New Orleans.<br /><br />
                  Because when you follow the Captain, adventures become the stuff of legend.
                </div>
                <div className="contentblock__columns columns text--white flex">
                  <div className="columns__heading text--copy">
                    Check back every week for new prizes and adventure drops.
                  </div>
                  <div className="columns__wrap flex">
                    <div className="columns__item">
                      <div className="columns__imgwrap">
                        <img className="columns__image" src={prizeTicket} aria-label="Tickets" />
                      </div>
                      <div className="columns__title">
                        <span className='head'>Exclusive Tickets</span><br />
                        <span>Like...</span><br />
                        <span>Club Seats at Select Games</span>
                      </div>
                    </div>
                    <div className="columns__item">
                      <div className="columns__imgwrap">
                        <img className="columns__image" src={prizeShirt} aria-label="Jersey" />
                      </div>
                      <div className="columns__title">
                        <span className='head'>Team Swag</span><br />
                        <span>Like...</span><br />
                        <span>Autographed Helmets</span><br />
                        <span>Autographed Footballs</span><br />
                        <span>Framed Photos</span>
                      </div>
                    </div>
                    {/*<div className="columns__item">
                      <img className="columns__image" src={prizeBox} aria-label="Toolbox" />
                      <div className="columns__title">
                        Lorem ipsum dolor sit amet consectetur adipscing elit, ses.
                      </div>
                    </div>*/}
                    <div className="columns__item">
                      <div className="columns__imgwrap">
                        <img className="columns__image" src={prizeFb} aria-label="Football" />
                      </div>
                      <div className="columns__title">
                         <span className='head'>Unique Experiences</span><br />
                        <span>Like...</span><br />
                        <span>Pre-Game Warm Ups</span><br />
                        <span>Sideline Passes</span><br />
                        <span>Tunnel Experiences</span><br />
                        <span>Travel to Team Away Games</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="image__wrap flex">
              <img className="image__item image__item--desktop" src={aboutBg} aria-hidden="true" />
              <img className="image__item image__item--mobile" src={aboutBgMobile} aria-hidden="true" />
            </div>
          </section>
          <Partners
            contentCopy="Trace where the Captain has been."
            title="HAVE YOU FOLLOWED THE CAPTAIN?"
          />
          <section className="flex otherinfo">
            <div className="container container--1440" style={{paddingTop: '40px', paddingBottom: '40px'}}>
                <HeadingWithCopy
                // title="Join our crew to get notified of legendary celebrations throughout the season!"
                 contentCopy="Join our crew to get notified of legendary celebrations throughout the season!"
                // contentCopy="Have you followed the Captain?"
              />
              <div className="button__wrap">
                <RenderButton callback={() => this.pModalClickHandler()} />
              </div>
            </div>
          </section>
        </main>        
        <Footer />
      </div>
    );
  }
}
