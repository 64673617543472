import "./style.scss";
import React from "react";

import CardGame from './card-game'
import BottleGame from './bottle-game'

import btnBack from 'global/images/ftc2024/btn__backtomap.png'

const GameLayer = (props) => {
  const currentGame = props && props.game && props.game !== "" ? props.game : ""
  const clickhandler = props.callback

  if(props.game === "") return null

  return (
    <div className="gamelayer" id="gamelayer">
      <div className="gamelayer__wrap">
        <button
          aria-label="back to map"
          className="gamelayer__close"
          onClick={() => clickhandler()}
          type="button"
        >
          <img src={btnBack} width="300px" height="130px" aria-label="Back to map" />
          <span className="sr-only">Back to map</span>
        </button>
        {currentGame === 'card' &&
          <CardGame />
        }
        {currentGame === 'bottle' &&
          <BottleGame />
        }
      </div>
    </div>
  );
};

export default GameLayer;
